body {
    font-family: "Inter", sans-serif;
    header {
        .navbar {
            padding: 0.75rem 1rem;
        }
        .submenu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 1rem;
            border-top: 1px solid $st-gray_03;
            a.back {
                color: white;
                font-size: $fs-15;
                font-weight: 500;
                background-color: $st-green_01;
                padding: 0.35rem 0.75rem;
                border-radius: 5px;
                &:hover {
                    background-color: $st-green_03;
                }
            }
            ul {
                margin: 0 0 0 auto;
                padding: 0;

                li {
                    list-style: none;
                    a {
                        color: $st-green_02;
                        font-size: $fs-16;
                        font-weight: 500;
                        &:hover {
                            color: $st-guinda_03;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    main {
        background-color: white;
        #bandeja {
            .encabezado {
                color: $st-gray_06;
                font-size: $fs-30;
                font-weight: 800;
                line-height: 36px;
                letter-spacing: -0.03em;
                background-color: $st-gray_02;
                border-radius: 15px;
                padding: 0.75rem 2rem;
            }
            .sidebar {
                background: $st-white_02;
                box-shadow: 0px 16px 85px rgba(0, 146, 135, 0.0395542);
                border-radius: 10px 10px 10px 0px;
                padding: 1.5rem 2rem;
                form {
                    .st-label,
                    .st-input {
                        display: block;
                        font-size: $fs-15;
                        font-weight: 600;
                    }
                }
            }

            table {
                thead {
                    th {
                        color: white;
                        font-size: $fs-15;
                        font-weight: 600;
                        line-height: 18px;
                        text-align: center;
                        background-color: $st-guinda_02;
                        border-top: none;
                        &:first-child {
                            border-top-left-radius: 15px;
                        }
                        &:last-child {
                            border-top-right-radius: 15px;
                        }
                    }
                }
                tbody {
                    td {
                        color: black;
                        font-size: $fs-15;
                        font-weight: 500;
                        line-height: 18px;
                        &.opciones {
                            display: flex;
                            a,
                            button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: white;
                                font-size: $fs-14;
                                font-weight: 600;
                                line-height: 18px;
                                background-color: $st-guinda_02;
                                padding: 0.3rem 0.8rem;
                                border-radius: 16px;
                                &:hover {
                                    background-color: $st-guinda_03;
                                    text-decoration: none;
                                }
                                img {
                                    width: 19px;
                                    margin: 0 0.35rem 0 0;
                                }
                            }
                        }
                    }
                }
                tr {
                    background-color: $st-white_01;
                }
            }

            ul.pagination {
                min-width: 100%;
                justify-content: center;
                li {
                    &.page-item {
                        padding: 0.15rem;
                        &:first-child,
                        &:last-child {
                            font-size: 1rem;
                            color: black !important;
                            font-weight: 800;
                            .page-link {
                                padding-top: 0.4rem;
                            }
                        }
                        &.active {
                            .page-link {
                                z-index: 3;
                                color: white !important;
                                background-color: $st-gold_01;
                            }
                        }
                        &.disabled {
                            .page-link {
                                color: $st-gray_03 !important;
                                font-weight: normal;
                            }
                        }
                    }
                }
                .page-link {
                    color: black;
                    line-height: normal;
                    border: none;
                    border-radius: 5px;
                }
            }
        }

        .form {
            .encabezado {
                color: white;
                font-size: $fs-28;
                font-weight: 800;
                line-height: normal;
                background-color: $st-guinda_02;
                border-radius: 10px 10px 0px 0px;
                padding: 1rem 2rem;
            }
            form {
                background-color: white;
                .st-label,
                .st-input {
                    font-size: $fs-18;
                    font-weight: 500;
                }
                .cont-dp {
                    button.ui-datepicker-trigger {
                        padding: 0.8rem 0.7rem 0.7rem;
                    }
                }
                select.st-input {
                    background-position: calc(100% - 19px) calc(1em + 1px), calc(100% - 13px) calc(1em + 1px), 100.5% 0;
                    background-size: 7px 7px, 7px 7px, 2.3em 2.5em;
                }
            }
            .actions {
                text-align: right;
                background: rgba(152, 152, 154, 0.25);
                border-radius: 0px 0px 10px 10px;
                padding: 1rem 2rem;
            }
        }

        #successful {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 60vh;
            .message {
                text-align: center;
                color: $st-gray_06;
                letter-spacing: -0.03em;
                line-height: normal;
                .title {
                    font-size: $fs-32;
                    font-weight: 800;
                }
                .subtitle {
                    font-size: $fs-26;
                    font-weight: 600;
                }
                hr {
                    width: 30px;
                    border-top: 4px solid $st-guinda_01;
                }
            }
        }
    }
}
