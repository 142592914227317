.cont-dp {
    position: relative;
    input {
        cursor: pointer;
    }
    button.ui-datepicker-trigger {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0.5rem 0.625rem 0.6rem;
        background-color: $st-gold_02;
        border-radius: 0 7px 7px 0;
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: $st-gold_03;
        }
        img {
            width: 18.75px;
        }
    }
    &:hover {
        button {
            background-color: $st-gold_03;
        }
    }
}

// Input
input {
    &.input-dp {
        width: 100%;
        height: auto;
        background-color: white;
        color: $st-gray_04;
        font-size: $fs-13 !important;
        font-weight: normal;
        line-height: normal;
        border: 1px solid $st-gray_02;
        border-radius: 6px;
        margin: 0.35rem 0 0;
        padding: 0.47rem 0.75rem 0.55rem;
        cursor: pointer;
        &:focus {
            background-color: white;
            border-color: $st-green_01;
            box-shadow: 0 0 0 0.05rem $st-green_01;
        }
    }
}

// Widget
#ui-datepicker-div {
    display: none;
    z-index: 99999 !important;
    background-color: white;
    border: 0 solid rgb(216, 216, 216);
    -webkit-box-shadow: 1px 10px 31px 13px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 1px 10px 31px 13px rgba(0, 0, 0, 0.24);
    box-shadow: 1px 10px 31px 13px rgba(0, 0, 0, 0.24);
    .ui-datepicker-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 0.35rem;
        border: none;
        background: white;
        .ui-datepicker-title {
            text-align: left;
            select {
                display: inline;
                color: $st-green_01;
                font-size: $fs-10 !important;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.25px;
                background-color: transparent;
                background: url("../images/ico-date.svg") no-repeat right;
                border: none;
                cursor: pointer;
                -webkit-appearance: none;
                -ms-appearance: none;
                -moz-appearance: none;
                appearance: none;
                option {
                    color: $st-green_01;
                    font-size: $fs-14 !important;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.25px;
                }
                &:hover,
                &:focus {
                    color: $st-gray_05;
                    outline: none;
                }
            }
            .ui-datepicker-year {
                padding: 0 1rem 0 0 !important;
            }
            .ui-datepicker-month {
                margin-left: 1rem;
                padding: 0 1.1rem 0 0 !important;
            }
        }
        .ui-datepicker-prev {
            color: $st-green_01;
            float: left;
            cursor: pointer;
            background-position: center 0;
            font-size: 0.9em;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            &:hover,
            &:focus {
                color: $st-gray_05;
                outline: none;
            }
            &.ui-state-disabled {
                pointer-events: none;
                span {
                    color: $st-gray_03;
                }
            }
        }
        .ui-datepicker-next {
            color: $st-green_01;
            float: right;
            cursor: pointer;
            background-position: center 0;
            font-size: 0.9em;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            order: 3;
            &:hover,
            &:focus {
                color: $st-gray_05;
                outline: none;
            }
            &.ui-state-disabled {
                pointer-events: none;
                span {
                    color: $st-gray_03;
                }
            }
        }
    }
    table.ui-datepicker-calendar {
        width: 100%;
        thead {
            background-color: white;
            th {
                color: black;
                font-size: 0.55rem;
                letter-spacing: 0.5px;
                text-align: center;
            }
        }
        tbody {
            margin: 0;
            td {
                width: 1.25rem;
                height: 1.25rem;
                color: black;
                font-size: 0.5rem;
                font-weight: 600;
                background-color: white;
                margin: 1px;
                padding: 1px;
                a,
                span {
                    display: inline-block;
                    text-align: center;
                }
                .ui-state-default {
                    background: $st-yellow_01;
                    color: $st-gold_01 !important;
                    font-size: 0.5rem;
                    font-weight: bold;
                    height: 1.25rem;
                    width: 1.25rem;
                    border: none;
                    border-radius: 0;
                    padding: 0.25rem;
                }
                .ui-state-hover,
                .ui-state-active {
                    color: white !important;
                    text-decoration: none;
                    background-color: $st-guinda_02;
                }
                &.ui-datepicker-unselectable {
                    .ui-state-default {
                        color: $st-gray_04;
                        background-color: $st-gray_02;
                    }
                }
                &:last-child {
                    border-right: 1px;
                }
            }
        }
    }
}
